import React, { useState, useEffect, useCallback } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import loadable from '@loadable/component'
import { s, colors, alpha } from '../clever-ui-kit/style'
import { ButtonReactive } from '../clever-ui-kit/buttons'
import FormBackdrop from '../clever-ui-kit/forms/FormItems/FormBackdrop'
import { Grid, TextField } from '@material-ui/core'
import { sTextInput } from '../clever-ui-kit/forms/FormItems/FormInputs'
import FormAgreements from '../clever-ui-kit/forms/FormItems/FormAgreements'
import { validateEmail } from '../clever-ui-kit/hooks/utils'
import axios from 'axios'
import SEO from '../components/seo'

const Intro = loadable(() => import('../clever-ui-kit/intros/Intro/Intro'))

const data = {
  align: 'left',
  language: 'pl',
  titleComplex: {
    variant: 'h1',
    texts: [
      {
        fontWeight: 'bold',
        text: 'Umów się na',
        uppercase: true,
        gradient: {
          angle: 45,
          colors: [{ color: '#ffffff' }, { color: '#ece7e7' }],
        },
      },
      {
        fontWeight: 'bold',
        text: 'Konsultacje',
        uppercase: true,
        gradient: {
          angle: 45,
          colors: [{ color: '#ff0000' }, { color: '#770000' }],
        },
      },
      // {
      //   fontWeight: 'bold',
      //   text: 'Marketingowe',
      //   uppercase: true,
      //   gradient: {
      //     angle: 45,
      //     colors: [{ color: '#ffffff' }, { color: '#ece7e7' }],
      //   },
      // },
    ],
  },
  subtitleObject: {
    fontWeight: `bold`,
    variant: `h2`,
    text: `Rozpocznij zmiany w swoim marketingu`,
    uppercase: true,
    color: '#ffffff',
  },
  descriptionObject: {
    fontWeight: 'normal',
    variant: 'h3',
    text: `Na spotkaniu porozmawiamy o tym jak teraz wygląda Twój marketing i przygotujemy dla Ciebie raport, w którym zaproponujemy działania potrzebne Twojej marce! 
    Zacznij jak najszybciej sprawnie zarządzać swoją komunikacją wybierając najszybszą drogę z Reklamą Dla Biznesu`,
    color: `rgba(255, 255, 255, 0.6)`,
  },
}

const form_success = {
  title: 'Wysłano',
  text:
    'Dziękujemy! Twoja wiadomość została wysłana. Oczekuj na odpowiedź drogą mailową lub telefonicznie',
}

const form_failure = {
  text:
    'Wygląda na to, że Twoja wiadomość nie została wysłana. Prosimy skontaktować się z nami mailowo',
  title: 'Niepowodzenie',
}

const form_button = {
  send: 'Wyślij!',
  fill: 'Wypełnij pola!',
}

const form_agreements = [
  {
    text:
      'Wysyłając formularz zgłoszeniowy potwierdzasz zgodę na przetwarzanie danych osobowych przez ReklamaDlaBiznesu.pl Sp. z o.o. oraz przesyłanie informacji handlowych za pomocą środków komunikacji elektronicznej na podany adres e-mail',
  },
]

const Konsultacje = () => {
  const { imageQuality } = useStaticQuery(graphql`
    {
      imageQuality: file(
        absolutePath: { regex: "/images/constants/rdb-image-min.png/" }
      ) {
        name
        childImageSharp {
          fluid(
            maxWidth: 1920
            quality: 100
            toFormat: JPG
            jpegProgressive: true
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const theme = 'light'

  const initialFormData = {
    name: '',
    email: '',
    phone: '',
    date: new Date().toISOString().split('.')[0],
  }

  const initialFormAgreements = form_agreements.map((e) => false)
  const [formData, setFormData] = useState(initialFormData)
  const [formAgreements, setFormAgreements] = useState(initialFormAgreements)
  const [mailState, setMailState] = useState('unfilled')
  const [success, setSuccess] = useState(null)
  const [openBackdrop, setOpenBackdrop] = useState(false)

  const agreementFilled =
    formAgreements.filter((value) => value).length === formAgreements.length

  const formDataEmail = formData.email

  const validate = useCallback(() => {
    return agreementFilled && validateEmail(formDataEmail)
  }, [agreementFilled, formDataEmail])

  const handleChangeForm = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  // const resetAll = () => {
  //   setOpenBackdrop(false)
  //   setSuccess(undefined)
  //   setFormData(initialFormData)
  // }

  const sendMail = async () => {
    if (validate()) {
      setSuccess(null)
      setOpenBackdrop(true)
      setMailState('loading')
      axios({
        method: 'POST',
        url: 'https://cleverserver.appclever.pl/sendmail',
        // url: 'http://localhost:5000/sendmail',
        data: {
          ...formData,
          toEmail: 'serverSide',
          // toEmail: 'pp@rdbgroup.pl',
          // cc: [{ email: 'ad@rdbgroup.pl' }],
          template: 'ReklamaKonsultacje',
        },
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            setOpenBackdrop(true)
            setSuccess(true)
            setFormData(initialFormData)
            setFormAgreements(initialFormAgreements)
            // console.log('TRUE')
            // console.log(res)
          } else {
            setOpenBackdrop(true)
            setSuccess(false)
            // console.log('FALSE')
            // console.log(res)
          }
        })
        .catch((error) => {
          setOpenBackdrop(true)
          setSuccess(false)
          // console.log('ERROR')
          // console.log(error)
        })
    }
  }

  useEffect(() => {
    if (validate()) setMailState('filled')
    else setMailState('unfilled')
  }, [validate, setMailState])

  return (
    <>
      <SEO
        title="Konsultacje Marketingowe"
        description="Na spotkaniu porozmawiamy o tym jak teraz wygląda Twój marketing i przygotujemy dla Ciebie raport, w którym zaproponujemy działania potrzebne Twojej marce!"
      />
      <div
        css={{
          width: '100vw',
          height: '150vh',
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: colors[theme].footer.background[0],
        }}
      />
      <div
        css={{
          position: 'relative',
          height: '100%',
          backgroundColor: colors[theme].footer.background[0],
        }}>
        <Intro data={{ ...data, imageQuality }} theme={theme} maxWidth={1600} />
        <div css={sFormContainer}>
          <div css={sForm}>
            <Grid container>
              <Grid item xs={12} css={sInputGrid}>
                <TextField
                  onChange={handleChangeForm}
                  name="name"
                  value={formData.name}
                  label={'Nadawca'}
                  variant="filled"
                  type="text"
                  css={sTextInput(theme)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} css={sInputGrid}>
                <TextField
                  onChange={handleChangeForm}
                  name="email"
                  value={formData.email}
                  label={'Email'}
                  variant="filled"
                  type="email"
                  css={[
                    sTextInput(theme),
                    formDataEmail !== '' &&
                      !validateEmail(formDataEmail) && {
                        'label, input': { color: 'red !important' },
                      },
                  ]}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} css={sInputGrid}>
                <TextField
                  onChange={handleChangeForm}
                  name="phone"
                  value={formData.phone}
                  label={'Telefon'}
                  variant="filled"
                  type="tel"
                  css={sTextInput(theme)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} css={sInputGrid}>
                <TextField
                  onChange={handleChangeForm}
                  name="date"
                  id="datetime-local"
                  defaultValue="2017-05-24T10:30"
                  value={formData.date}
                  label={'Termin'}
                  variant="filled"
                  css={sTextInput(theme)}
                  type="datetime-local"
                  fullWidth
                />
              </Grid>
            </Grid>
            <FormAgreements
              // isExpanded={false}
              hideWhiteHidder
              theme={theme}
              data={form_agreements}
              formAgreements={formAgreements}
              setFormAgreements={setFormAgreements}
              extraCssContainer={{
                [s.md]: { marginTop: 0, marginBottom: '1rem' },
              }}
            />
            <ButtonReactive
              onClickFunction={sendMail}
              active={mailState === 'filled'}
              theme={theme}
              center>
              {mailState === 'unfilled'
                ? form_button.fill
                : mailState === 'filled'
                ? form_button.send
                : form_button.send}
            </ButtonReactive>
            <FormBackdrop
              success={success}
              theme={theme}
              open={openBackdrop}
              setOpen={setOpenBackdrop}
              dataSuccess={form_success}
              dataFailure={form_failure}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const sFormContainer = {
  position: 'absolute',
  right: 0,
  top: 0,
  // backgroundColor: 'rgba(0,255,0,0.25)',
  height: '100%',
  [s.xs]: {
    padding: '4rem 0',
  },
  [s.ss]: {
    paddingTop: 0,
    paddingBottom: '4rem',
  },
  [s.md_down]: {
    width: '100vw',
    minHeight: 300,
    position: 'relative',
  },
  [s.sm]: { padding: '2rem' },
  [s.lg]: { width: '40%', paddingRight: '4rem' },

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
}

const sForm = {
  width: '100%',
  height: 'max-content',
  minHeight: 300,
  borderRadius: '2rem',
  background: `linear-gradient(to bottom right, ${colors.white.concat(
    alpha[90]
  )}, ${colors.white.concat(alpha[30])})`,
  padding: '2rem',
  [s.xs]: {
    padding: '1.5rem',
  },
}

const sInputGrid = {
  marginBottom: '1rem',
}

export default Konsultacje
